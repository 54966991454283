import * as ep from "../../../../endpoints";

export default {
    methods:{
        switchEndPoint(router){
            switch (router) {
                case "recording-studio":
                    return "/studio-appointments"
                case "bookings-halls":
                    return "/hall-appointments"
                default:
            }
        },
        
        switchTitlePage(router){
            switch (router) {
                case "recording-studio":
                    return "حجوزات استوديو التسجيل"
                case "bookings-halls":
                    return "حجوزات القاعات"
                default:
            }
        },

        switchCompleted(router,id){
            switch (router) {
                case "recording-studio":
                    return ep.COMPLETED_STUDIO_APPOINTMENTS(id)
                case "bookings-halls":
                    return ep.COMPLETED_HALL_APPOINTMENTS(id)
                default:
            }
        }
    }
}